<template>
  <div class="topic_container">
    <!-- 按钮 -->
    <div class="search_btn">
      <el-button
        size="medium"
        type="primary"
        icon="el-icon-plus"
        @click="addTopic"
        >添加专题</el-button
      >
      <el-button
        size="medium"
        type="warning"
        icon="el-icon-plus"
        @click="goClassify"
        >添加专题分类</el-button
      >
    </div>

    <!-- 表格数据区域 -->
    <el-table
      style="width: 100%"
      border
      stripe
      :data="topicsList"
      :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
    >
      <el-table-column
        show-overflow-tooltip
        prop="topicName"
        label="专题名称"
        align="center"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="categoryTitle"
        label="专题分类"
        align="center"
      ></el-table-column>

      <!-- <el-table-column
        align="center"
        prop="createTime"
        label="创建时间"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{ scope.row.createTime | getLocalTime }}
        </template>
      </el-table-column> -->
      <el-table-column align="center" label="设置轮播图" width="251px">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="define(scope.row)"
            :disabled="scope.row.isPics == 0 ? true : false"
          >
            确定
          </el-button>

          <el-button
            type="text"
            size="small"
            @click="cancel(scope.row)"
            :disabled="scope.row.isPics == 1 ? true : false"
          >
            取消
          </el-button>
        </template>
      </el-table-column>

      <el-table-column align="center" label="操作" width="251px">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editor(scope.row)">
            编辑
          </el-button>
          <el-button type="text" size="small" @click="remove(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="topicsInfo.page"
      :limit.sync="topicsInfo.pageSize"
      @pagination="getTopics"
    />

    <addTopic :showDialog.sync="showDialog" @addSubmit="getTopics"></addTopic>

    <!-- 编辑 -->
    <el-dialog title="提示" :visible.sync="editorDialog">
      <!-- 表单数据 -->
      <el-form
        label-width="120px"
        :model="formData"
        :rules="rules"
        ref="addForm"
      >
        <el-form-item label="专题名称" prop="topicName">
          <el-input
            style="width: 80%"
            placeholder="请输入专题名称"
            v-model.trim="formData.topicName"
          ></el-input>
        </el-form-item>

        <el-form-item label="专题标签" prop="tags">
          <el-input
            style="width: 80%"
            placeholder="请输入专题标签(多个用英文逗号隔开)"
            v-model.trim="formData.tags"
          ></el-input>
        </el-form-item>

        <el-form-item label="专题图片" prop="logoId">
          <el-upload
            ref="upload"
            :limit="1"
            :data="postData"
            list-type="picture-card"
            show-file-list
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            action="http://up-z0.qiniup.com"
            :file-list="fileList"
            multiple
            :on-remove="handleRemove"
            :on-exceed="exceed"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="专题摘要" prop="abstracts">
          <el-input
            type="textarea"
            :rows="2"
            style="width: 80%"
            placeholder="请输入专题摘要"
            v-model.trim="formData.abstracts"
          ></el-input>
        </el-form-item>

        <el-form-item label="专题分类" prop="categoryId">
          <el-select v-model="formData.categoryId" placeholder="专题分类">
            <el-option
              v-for="item in classifyList"
              :key="item.categoryId"
              :label="item.categoryName"
              :value="item.categoryId"
            >
            </el-option>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="queryInfo.page"
              :page-size="queryInfo.pageSize"
              layout="total, prev, pager, next"
              :total="count"
            >
            </el-pagination>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 确定和取消按钮 -->
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" @click="btnCancel">取消</el-button>
          <el-button type="primary" size="small" @click="btnSubmit"
            >确定</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTopics,
  delTopic,
  getCategories,
  getUploadToken,
  updateTopic,
  setPics,
  delPics
} from '../../request/http'
import addTopic from '../specialTopic/components/addTopic.vue'

export default {
  data() {
    return {
      showDialog: false,
      editorDialog: false,
      topicsInfo: {
        page: 1,
        pageSize: 10
      },
      total: 0,
      topicsList: [],

      formData: {
        topicName: '', //专题名称
        logoId: '', //专题图片
        abstracts: '', //专题摘要
        categoryId: '', //专题分类id
        tags: '' //标签
      },
      //校验规则
      rules: {
        topicName: [
          { required: true, message: '请输入专题名称', trigger: 'blur' }
        ],
        logoId: [
          { required: true, message: '请上传专题图片', trigger: 'blur' }
        ],
        abstracts: [
          { required: true, message: '请输入专题摘要', trigger: 'blur' }
        ],
        categoryId: [
          { required: true, message: '请选择专题分类', trigger: 'blur' }
        ],
        tags: [{ required: true, message: '请填写标签', trigger: 'blur' }]
      },
      postData: {
        token: '',
        key: '' //上传的Logo
      },
      classifyList: [],
      queryInfo: {
        page: 1,
        pageSize: 10
      },
      count: 0,
      fileList: []
    }
  },
  created() {
    this.getTopics() //获取专题
    this.getCategories() //分类列表
  },
  components: {
    addTopic
  },
  methods: {
    async getTopics() {
      const res = await getTopics(this.topicsInfo)
      console.log(res)
      if (res.code !== 0) {
        return this.$message.error(res.code)
      }
      this.topicsList = res.data
      this.total = res.count
    },

    goClassify() {
      this.$router.push({
        path: '/classify'
      })
    },

    // 添加专题
    addTopic() {
      this.showDialog = true
    },

    //删除添加专题
    async remove(row) {
      console.log(row)
      try {
        await this.$confirm('确定要删除吗')
        await delTopic({ topicId: row.topicId })
        const totalPage = Math.ceil((this.total - 1) / this.topicsInfo.pageSize)
        this.topicsInfo.page =
          this.topicsInfo.page > totalPage ? totalPage : this.topicsInfo.page
        this.topicsInfo.page =
          this.topicsInfo.page < 1 ? 1 : this.topicsInfo.page
        this.getTopics()
        this.$message.success('删除成功')
      } catch (error) {
        console.log(error)
      }
    },

    editor(row) {
      console.log(row)
      this.editorDialog = true
      this.formData = row
      this.fileList.push({
        url: 'http://file.innopinenut.com/' + row.logoId
      })

      this.fileList.forEach((item, index) => {
        if (item.url == 'http://file.innopinenut.com/' + row.logoId) {
          this.fileList.splice(1)
        }
      })
    },

    async getCategories() {
      const res = await getCategories(this.queryInfo)
      console.log(res)
      if (res.code !== 0) {
        return this.$message.error(res.code)
      }
      this.classifyList = res.data
      this.count = res.count
    },
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.getCategories()
    },
    handleCurrentChange(val) {
      this.queryInfo.page = val
      this.getCategories()
    },
    // 上传图片方法
    handleAvatarSuccess(res, file) {
      this.formData.logoId = res.key
    },
    async beforeAvatarUpload(file) {
      let { data } = await getUploadToken()
      if (data == '' || data == null) {
        this.message.error('上传图片时获取Token失败！')
        return
      }
      this.postData.token = data.token
      this.postData.key = data.fid
    },
    // 删除图片
    handleRemove(file) {
      let uid = file.uid
      this.fileList.forEach((item, index) => {
        if (item.uid == uid) {
          this.fileList.splice(index, 1)
        }
      })
    },
    // 最多上传一张图片
    exceed(files, fileList) {
      if (fileList.length == 1) {
        return this.$message.error('最多只能上传一张图片。')
      }
    },
    btnSubmit() {
      // 校验表单
      this.$refs.addForm.validate(async (valid) => {
        if (valid) {
          const res = await updateTopic(this.formData)
          // 告诉父组件,触发一个自定义事件
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message.success('编辑专题成功')
          this.editorDialog = false
          this.getTopics() //获取专题
        }
      })
    },
    // 取消表单验证按钮
    btnCancel() {
      // 关闭弹层
      this.editorDialog = false
      // 清除校验
      this.$refs.addForm.resetFields()
      //清除图片
      this.$refs.upload.clearFiles()
    },

    async define(row) {
      console.log(row)
      const confirmResult = await this.$confirm(
        '是否设置此专题为轮播图?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消')
      }
      const res = await setPics(row.topicId)
      console.log(res)

      if (res.data.code !== 0) {
        return this.$message.error(res.data.msg)
      }
      this.$message.success('设置成功')
      this.getTopics() //获取专题
    },

    async cancel(row) {
      console.log(row)
      const confirmResult = await this.$confirm(
        '是否取消此专题为轮播图?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消')
      }
      const res = await delPics(row.topicId)
      console.log(res)
      if (res.data.code !== 0) {
        return this.$message.error(res.data.msg)
      }
      this.$message.success('取消成功')
      this.getTopics() //获取专题
    }
  }
}
</script>


<style scoped lang='scss'>
.topic_container {
  margin: 20px;
  .search_btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}
</style>